import React from 'react'
import './solutions.css'

const Solutions = () => {
  return (
    <>
       <div className="container-fluid sec-solutions-top py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className='align-items-center d-flex'>
                                <div className='me-5 num'>
                                    <span>01</span>
                                </div>
                                <div className='ms-3'>
                                    <p className='home-solutions-text-b'>INDUSTRIAL</p>
                                    <p className='home-solutions-text-i'>E Waste Management</p>
                                    <p className='home-solutions-text-n'>We offer complete industrial waste management services, from collection to recycling.</p>
                                </div>
                            </div>
                            <div className='align-items-center d-flex py-4'>
                                <div className='me-5 num'>
                                    <span>02</span>
                                </div>
                                <div>
                                    <p className='home-solutions-text-b'>SUSTAINABLE</p>
                                    <p className='home-solutions-text-i'>Business Solutions</p>
                                    <p className='home-solutions-text-n'>We help business adopt sustainable and environmentally responsible recycling practices</p>
                                </div>
                            </div>
                            <div className='align-items-center d-flex'>
                                <div className='me-5 num'>
                                    <span>03</span>
                                </div>
                                <div>
                                    <p className='home-solutions-text-b'>ENVIRONMENTAL</p>
                                    <p className='home-solutions-text-i'>Quality Services</p>
                                    <p className='home-solutions-text-n'>We focus on disposing waste sustainably and minimizing environmental impact</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={process.env.PUBLIC_URL + '/assets/img/home/solutions/recycle.png'} alt="" className='w-100 rounded-3' />
                        </div>
                    </div>

                </div>
            </div>
            <div className="container-fluid home-solutions-band">
                <div className="container">
                    <div className="row align-items-center py-3">
                        <div className="col-md-10">
                            <p className='mb-0'>Reach us for complete ewaste recycling solutions</p>
                        </div>
                        <div className="col-md-2 text-end">
                            <a href="https://www.google.com/" className="home-solutions-btn">Start Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-5 pb-5 sec-solutions">
                <div className="container">

                    <div className="row">
                        <div className="col-md-10 mx-auto text-center home-solutions">
                            <h5>Knowledge</h5>
                            <h3>Reduce Reuse Recycle</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            {/* <div className=''> */}

                            <ul className="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">01</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">02</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">03</button>
                                </li>

                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <img src={process.env.PUBLIC_URL + '/assets/img/home/solutions/one.png'} alt="" className='w-100' />
                                        </div>
                                        <div className="col-md-6 text-start">
                                            <p className='home-solutions-heading'>What is E-Waste?</p>
                                            <p>E-waste is defined as electronic or electrical equipment’s/products that have become obsolete because of advancement of technology or change in fashion.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <img src={process.env.PUBLIC_URL + '/assets/img/home/solutions/two.png'} alt="" className='w-100' />
                                        </div>
                                        <div className="col-md-6 text-start">
                                            <p className='home-solutions-heading'>Benefits of Recycling.</p>
                                            <p>If “Managed” safely by Recycling, E-waste can be a secondary source of raw material, other benefits are :</p>
                                            <ul>
                                                <li>Economic Benefits - Revenue generation from recovered materials</li>
                                                <li>Environmental Benefits - Natural resource conservation & Reduction in Environmental pollution</li>
                                                <li>Social Benefits - Employment generation</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <img src={process.env.PUBLIC_URL + '/assets/img/home/solutions/three.png'} alt="" className='w-100' />
                                        </div>
                                        <div className="col-md-6 text-start">
                                            <p className='home-solutions-heading'>Reasons to Recycle</p>
                                            <ul>
                                                <li>The Earth's natural resources are limited and hence we need to make sure that we preserve them and use them carefully.</li>
                                                <li> To eliminate landfill.</li>
                                                <li>To save mother Earth by preventing air, water and soil pollution.</li>
                                                <li>   To generate employment opportunity.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}

            </div>
    </>
  )
}

export default Solutions
