import React from 'react'
import './initiatives.css'

const Initiatives = () => {
    return (
        <>
            <div className="container-fluid initiatives-sec-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 mx-auto text-center">
                            <p className='hero-title mb-0'>EHS INITIATIVES</p>
                            <h6>Our Environment and Safety initiatives and training program for skill development E-Parisaraa is first Indian E-waste recycler to obtain both ISO 14001:2004 and OHSAS 18001:2007 certifications, by TUV-SUD.</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5 initiatives-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <h3 className=''>Heath and safety</h3>
                            <p>Heath and safety initiatives of E- Parisaraa are periodic heath check-ups for all the Employees, emergencies response plans, mock drills, first aid trainings and Safety audits.</p>
                            <div className="row align-items-center">
                                <div className="col-md-3">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/about/initiatives/safetyone.jpeg'} alt="Heath and safety" className='w-100 rounded-3'/>
                                </div>
                                <div className="col-md-3">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/about/initiatives/safetytwo.jpeg'} alt="Heath and safety" className='w-100 rounded-3'/>
                                </div>
                                <div className="col-md-3">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/about/initiatives/safetythree.jpeg'} alt="Heath and safety" className='w-100 rounded-3'/>
                                </div>
                                <div className="col-md-3">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/about/initiatives/safetyfour.jpeg'} alt="Heath and safety" className='w-100 rounded-3'/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row col-md-12 mt-5">

                        <h3>Safety devices</h3>
                        <p>E- Parisaraa is well equipped with Safety devices like smoke detectors, radio activity measuring device, fire alarm, emergency showers, sand buckets etc. Pollution control devices like cyclone separators with back house, dust collector, etc and Security devices like CCTV camera, Metal detector etc.</p>
                        <di className="row text-center">
                            <div className="col-md-3">
                                <img src={process.env.PUBLIC_URL + '/assets/img/about/initiatives/radioactivity.jpeg'} alt="Radioactivity" className='w-100 rounded-3' />
                                <h6 className='mt-3 fw-semibold'>Technical Checks for possible radioactivity</h6>
                            </div>
                            <div className="col-md-3">
                                <img src={process.env.PUBLIC_URL + '/assets/img/about/initiatives/CCTV.jpeg'} alt="CCTV System" className='w-100 rounded-3' />
                                <h6 className='mt-3 fw-semibold'>CCTV System</h6>
                            </div>
                            <div className="col-md-3">
                                <img src={process.env.PUBLIC_URL + '/assets/img/about/initiatives/fire-alarm.jpeg'} alt="Fire Alarm" className='w-100 rounded-3' />
                                <h6 className='mt-3 fw-semibold'>Fire Alarm System</h6>
                            </div>
                            <div className="col-md-3">
                                <img src={process.env.PUBLIC_URL + '/assets/img/about/initiatives/cyclone-seperator.jpeg'} alt="Cyclone Seperator" className='w-100 rounded-3' />
                                <h6 className='mt-3 fw-semibold'>Cyclone Seperator with Bag House</h6>
                            </div>
                        </di>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Initiatives
