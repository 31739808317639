import React from 'react'
import ProductTechnology from '../../components/ourservices/producttechnology/ProductTechnology'

const ProductTech = () => {
  return (
    <>
    <ProductTechnology/>
    </>
  )
}

export default ProductTech
