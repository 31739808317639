import React from 'react'
import './optimizedinnovation.css'

const OptimizedInnovation = () => {
    return (
        <>
            <div className="container-fluid sec-optimized-innovation">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            <p className='hero-title'>Optimized Innovation</p>
                            <p className='para-normal'>Not just adopting to technologies or using inventions. E-Parisaraa has been involved peculiarly in the Process optimizing the over all e-waste handling, recycling and building the sustainable environment.</p>
                            <img src={process.env.PUBLIC_URL + '/assets/img/ourservices/optimizedinnovation/optimized-innovation.png'} alt="Optimized Innovation" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OptimizedInnovation
