import React, { useState } from 'react';
import './navbar.css';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(null);
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const handleToggle = (menu) => {
    if (openMenu === menu) {
      setOpenMenu(null);
      setOpenSubMenu(null);
    } else {
      setOpenMenu(menu);
      setOpenSubMenu(null);
    }
  };

  const handleSubToggle = (submenu) => {
    setOpenSubMenu(openSubMenu === submenu ? null : submenu);
  };

  const handleCloseDropdown = (e) => {
    if (e.target.closest('.navbar-nav')) return;
    setOpenMenu(null);
    setOpenSubMenu(null);
  };

  React.useEffect(() => {
    document.addEventListener('click', handleCloseDropdown);
    return () => {
      document.removeEventListener('click', handleCloseDropdown);
    };
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg main-header">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img
              src={process.env.PUBLIC_URL + '/assets/img/common/headerlogoself.png'}
              alt="Header Logo"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse nav justify-content-end me-5" id="navbarSupportedContent">
            <ul className="navbar-nav gap-3 mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link" aria-current="page" to="/">Home</NavLink>
              </li>

              <li className={`nav-item dropdown ${openMenu === 'about' ? 'show' : ''}`}>
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  onClick={(e) => { e.preventDefault(); handleToggle('about'); }}
                >
                  About
                </a>
                <ul className={`dropdown-menu ${openMenu === 'about' ? 'show' : ''}`}>
                  <li><NavLink className="dropdown-item" to="/about" onClick={() => setOpenMenu(null)}>Management</NavLink></li>
                  <li><NavLink className="dropdown-item" to="/our-policies-and-mission" onClick={() => setOpenMenu(null)}>Policies & Mission</NavLink></li>
                  <li><NavLink className="dropdown-item" to="/ehs-initiatives" onClick={() => setOpenMenu(null)}>EHS INITIATIVES</NavLink></li>
                  <li><NavLink className="dropdown-item" to="/awards-and-endorsement" onClick={() => setOpenMenu(null)}>Awards and Endorsement</NavLink></li>
                  <li className={`dropdown-submenu ${openSubMenu === 'in-media' ? 'show' : ''}`}>
                  <NavLink
                      className="dropdown-item dropdown-toggle"
                      to="/in-media"
                      onClick={(e) => { e.stopPropagation(); handleSubToggle('in-media'); }}
                    >
                      In Media
                    </NavLink>
                    <ul className={`dropdown-menu ${openSubMenu === 'in-media' ? 'show' : ''}`}>
                      <li><NavLink className="dropdown-item" to="/mann-ki-baat-e-waste-handling" onClick={() => setOpenMenu(null)}>Mann Ki Baat - E Waste Handling</NavLink></li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li className={`nav-item dropdown ${openMenu === 'process' ? 'show' : ''}`}>
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  onClick={(e) => { e.preventDefault(); handleToggle('process'); }}
                >
                  Our Process
                </a>
                <ul className={`dropdown-menu ${openMenu === 'process' ? 'show' : ''}`}>
                  <li><NavLink className="dropdown-item" to="/benifits-of-recycling" onClick={() => setOpenMenu(null)}>BENIFITS OF RECYCLING</NavLink></li>
                  <li><NavLink className="dropdown-item" to="/we-recycle" onClick={() => setOpenMenu(null)}>WE RECYCLE</NavLink></li>
                </ul>
              </li>

              <li className={`nav-item dropdown ${openMenu === 'services' ? 'show' : ''}`}>
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  onClick={(e) => { e.preventDefault(); handleToggle('services'); }}
                >
                  Our Services
                </a>
                <ul className={`dropdown-menu ${openMenu === 'services' ? 'show' : ''}`}>
                  <li className={`dropdown-submenu ${openSubMenu === 'services-menu' ? 'show' : ''}`}>
                  <NavLink
                      className="dropdown-item dropdown-toggle"
                      to="/services"
                      onClick={(e) => { e.stopPropagation(); handleSubToggle('services-menu'); }}
                    >
                      Services
                    </NavLink>
                    <ul className={`dropdown-menu ${openSubMenu === 'services-menu' ? 'show' : ''}`}>
                      <li><NavLink className="dropdown-item" to="/data-center-decommisioning" onClick={() => setOpenMenu(null)}>Data Center Decommissioning</NavLink></li>
                      <li><NavLink className="dropdown-item" to="/enterprise-asset-disposition" onClick={() => setOpenMenu(null)}>Enterprise Asset Disposition</NavLink></li>
                      <li><NavLink className="dropdown-item" to="/managed-deployment" onClick={() => setOpenMenu(null)}>Managed IT Deployment</NavLink></li>
                      <li><NavLink className="dropdown-item" to="/data-destruction" onClick={() => setOpenMenu(null)}>Data Destruction</NavLink></li>
                      <li><NavLink className="dropdown-item" to="/environmental-reporting" onClick={() => setOpenMenu(null)}>Environmental Reporting</NavLink></li>
                      <li><NavLink className="dropdown-item" to="/optimized-innovation" onClick={() => setOpenMenu(null)}>Optimised Innovation</NavLink></li>
                      <li><NavLink className="dropdown-item" to="/product-technology" onClick={() => setOpenMenu(null)}>Product Technology</NavLink></li>
                      <li><NavLink className="dropdown-item" to="/reverse-supply-chain" onClick={() => setOpenMenu(null)}>Reverse Supply Chain</NavLink></li>
                    </ul>
                  </li>
                  <li><NavLink className="dropdown-item" to="/why-e-parisara" onClick={() => setOpenMenu(null)}>Why E-Parisaraa</NavLink></li>
                  <li><NavLink className="dropdown-item" to="/csr" onClick={() => setOpenMenu(null)}>CSR</NavLink></li>
                </ul>
              </li>

              <li className={`nav-item dropdown ${openMenu === 'papers' ? 'show' : ''}`}>
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  onClick={(e) => { e.preventDefault(); handleToggle('papers'); }}
                >
                  Papers Published
                </a>
                <ul className={`dropdown-menu ${openMenu === 'papers' ? 'show' : ''}`}>
                  <li><NavLink className="dropdown-item" to="/technical-papers" onClick={() => setOpenMenu(null)}>Papers Published</NavLink></li>
                  <li><NavLink className="dropdown-item" to="/gallery" onClick={() => setOpenMenu(null)}>Gallery</NavLink></li>
                </ul>
              </li>

              <li className={`nav-item dropdown ${openMenu === 'certifications' ? 'show' : ''}`}>
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  onClick={(e) => { e.preventDefault(); handleToggle('certifications'); }}
                >
                  Certifications
                </a>
                <ul className={`dropdown-menu ${openMenu === 'certifications' ? 'show' : ''}`}>
                  <li><NavLink className="dropdown-item" to="/consents" onClick={() => setOpenMenu(null)}>Certifications Dobbaspet</NavLink></li>
                  <li><NavLink className="dropdown-item" to="/certifications-hindupur" onClick={() => setOpenMenu(null)}>Certifications Hindupur</NavLink></li>
                </ul>
              </li>

              <li className={`nav-item dropdown ${openMenu === 'contact' ? 'show' : ''}`}>
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  onClick={(e) => { e.preventDefault(); handleToggle('contact'); }}
                >
                  Contact
                </a>
                <ul className={`dropdown-menu ${openMenu === 'contact' ? 'show' : ''}`}>
                  <li><NavLink className="dropdown-item" to="/contact" onClick={() => setOpenMenu(null)}>Contact US</NavLink></li>
                  <li><NavLink className="dropdown-item" to="/branch" onClick={() => setOpenMenu(null)}>Branches</NavLink></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
