import React from 'react'
import EParisara from '../../components/ourservices/whyeparisara/EParisara'

const WhyEparisaraa = () => {
  return (
    <>
      <EParisara/>
    </>
  )
}

export default WhyEparisaraa
