import React from 'react'
import './supplychain.css'

const SupplyChain = () => {
    return (
        <>
            <div className="container-fluid sec-supply-chain">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            <p className='hero-title'>Reverse Supply Chain</p>
                            
                        </div>
                        <div className="row align-items-center mb-4">
                            <div className="col-md-6">
                            <p className='para-normal'>Reverse Supply Chain refer to monitoring the life-cycle of products after they arrive at the end consumer. This could include how a product could potentially be reused, how it should be properly disposed of after use, and any other way where your expired product can create value.</p>
                            <p className='para-normal'>The reverse logistics that directly impact supply chains the most are the return of products from the end consumer back to the manufacturer.</p>
                            
                            </div>
                            <div className="col-md-6">
                            <img src={process.env.PUBLIC_URL + '/assets/img/ourservices/reversesupplychain/reverse-supply-chain.webp'} className="d-block w-100" alt="..." />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mx-auto text-start">
                            <p className='heading'>The Benefits of an Efficient Reverse Logistics Systems</p>
                            <p className='para-normal'>While many companies consider the return process to be a necessary evil that shouldn't be noticed, companies that implement an effective reverse logistics workflow can reap several benefits.</p>

                            <p className='para-normal'>Some of these benefits are:</p>
                            <ul>
                                <li><span>Reduced costs:</span> By planning ahead for returns and making the return order right, you can reduce related costs (administration, shipping, transportation, tech support, QA, etc.)</li>

                                <li><span>Faster service:</span> This refers to the original shipping of goods and the return / reimbursement of goods. Quickly refunding or replacing goods can help restore a customer’s faith in a brand.</li>

                                <li><span>Customer retention:</span> Dealing with errors is just as important as making sales. If a customer had a bad experience with your product, you have to make it right. Fulfillment blunders can create educational opportunities. Learn how to keep your customers happy and engaged with your company - even after you've made a mistake.</li>

                                <li><span>Reduced losses and unplanned profits:</span>  Recover the loss of investment in your failed product by fixing and restocking the unit, scrapping it for parts, or repurposing it in a secondary market. With a good reverse logistics program in place, you don’t have to leave money on the table. Take a product that would otherwise just cost your company money and turn it into an unforeseen asset.</li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SupplyChain
