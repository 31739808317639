import React from 'react'
import './hindupur.css'

const Hindupur = () => {
  return (
    <>
      <div className="container-fluid sec-hindupur">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            <p className='hero-title'>Certifications</p>
                            <p className='para-normal'>E Parisara has gathered all certificates from governing bodies, required for E waste recycling.</p>
                        </div>
                    </div>
                    <div className="row align-items-center text-center">
                        <div className="col-md-4 text-center mb-4">
                            <img src={process.env.PUBLIC_URL + '/assets/img/certifications/hindupur/45001-2018.jpg'} className="d-block w-100" alt="ISO 45001 : 2018" />
                            <p className="cert-text">ISO 45001 : 2018 Certification</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <img src={process.env.PUBLIC_URL + '/assets/img/certifications/hindupur/14001-2015.jpg'} className="d-block w-100" alt="ISO 14001 : 2015" />
                            <p className="cert-text">ISO 14001 : 2015 Certification</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <img src={process.env.PUBLIC_URL + '/assets/img/certifications/hindupur/9001-2015.jpg'} className="d-block w-100" alt="ISO 9001 : 2015" />
                            <p className="cert-text">ISO 9001 : 2015 Certification</p>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default Hindupur
