import React from 'react'
import Solutions from '../../components/home/solutions/Solutions'
import Services from '../../components/home/services/Services'
import Video from '../../components/home/video/Video'
import About from '../../components/home/about/About'
import Hero from '../../components/home/hero/Hero'

const Home = () => {
  return (
    <>
    <Hero/>
    <About/>
      <Services />
      <Solutions />
      <Video/>
    </>
  )
}

export default Home
