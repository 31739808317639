import React from 'react'
import './video.css'

const Video = () => {
    return (
        <>
            <div className="container-fluid video-section py-5">
                <div className="container">
                    <div className="row">
                        <div className='videosec'>
                            <div className="col-md-6 mx-auto">
                                <iframe style={{ width: '100%', height: '350px' }} src="https://www.youtube.com/embed/HzuXQUD9Rkg" title="Digital India: E-Waste Management (English)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="row video-text">
                        <div className="col-md-12">
                            <p className='mb-0 mt-5'>Don't wait and start recycling with us today</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Video