import React from 'react'
import Benifitss from '../../components/process/benifits/benefitdetails/Benifits'

const Benefits = () => {
  return (
    <>
      <Benifitss/>
    </>
  )
}

export default Benefits
