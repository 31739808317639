import React from 'react';
import './dobbaspete.css';

const Dobbaspete = () => {
    return (
        <>
            <div className="container-fluid sec-dobbaspete">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            <p className='hero-title'>Certifications</p>
                            <p className='para-normal'>E Parisara has gathered all certificates from governing bodies, required for E waste recycling.</p>
                        </div>
                    </div>
                    <div className="row align-items-center text-center">
                        <div className="col-md-3 mb-4">
                            <img src={process.env.PUBLIC_URL + '/assets/img/certifications/dobbaspete/ISO14001.jpg'} className="d-block w-100" alt="ISO 14001 : 2015" />
                            <p className="cert-text">ISO 14001 : 2015 Certification</p>
                        </div>
                        <div className="col-md-3 mb-4">
                            <img src={process.env.PUBLIC_URL + '/assets/img/certifications/dobbaspete/ISO9001.jpg'} className="d-block w-100" alt="ISO 9001 : 2015" />
                            <p className="cert-text">ISO 9001 : 2015 Certification</p>
                        </div>
                        <div className="col-md-3 mb-4">
                            <img src={process.env.PUBLIC_URL + '/assets/img/certifications/dobbaspete/ISO45001.jpg'} className="d-block w-100" alt="ISO 45001 : 2018" />
                            <p className="cert-text">ISO 45001 : 2018 Certification</p>
                        </div>
                        <div className="col-md-3 mb-4">
                            <img src={process.env.PUBLIC_URL + '/assets/img/certifications/dobbaspete/R2V3.jpg'} className="d-block w-100" alt="R2V3" />
                            <p className="cert-text">R2V3 Certification</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dobbaspete;
