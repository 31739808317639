import React from 'react'
import ContactForm from '../../components/contact/contactform/ContactForm'

const GetInTouch = () => {
  return (
    <>
      <ContactForm/>
    </>
  )
}

export default GetInTouch
