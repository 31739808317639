import React from 'react'
import './producttechnology.css'
import ImagesGallery from '../../common/imagesgallery/ImagesGallery.jsx'

const ProductTechnology = () => {
    return (
        <>
            <div className="container-fluid sec-product-technology">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            <p className='hero-title'>Product Technology</p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            <img src={process.env.PUBLIC_URL + '/assets/img/ourservices/producttechnology/product-technology.png'} alt="Product Technology" className='w-100 d-block'/>
                            <p className='para-normal'>CRT Monitor recylcing - Invented at E-Parisaraa</p>
                        </div>
                        <div className="col-md-6">
                            <p className='para-normal'>E-Parisaraa is ingeniously involved in inventing Product Technologies for  sustainable handling of e-waste and utilizing them in efficient manner. E-Parisaraa has adopted best in industry sustainable machinery and methods.</p>
                        </div>
                    </div>
                    <ImagesGallery/>
                </div>
            </div>
        </>
    )
}

export default ProductTechnology
