import React from 'react'
import './assetdisposition.css'

const AssetDisposition = () => {
    return (
        <>
            <div className="container-fluid sec-asset-disposition">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center hero-margin">
                            <p className='hero-title'>Enterprise Asset Disposition</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid sec-asset-disposition">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto text-center">
                            <h3>How We Do It</h3>
                            <p>E-Parisaraa offers end-to-end management of the entire IT Asset Disposition cycle starting
                                from asset decommissioning, collection, transportation, secure data disposal. Our IT Asset Disposal division deploys best-in-class tools & processes in compliance with industry standards and regulations.</p>
                            <img src={process.env.PUBLIC_URL + '/assets/img/ourservices/enterpriseAssetDisposition/assetdisposition.jpg'} className="w-100" alt="Data Center Decommisioning" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid sec-asset-disposition py-5 sec-cards">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            <h3>Service Expertise Include</h3>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-3">
                            <div className="card border-0">
                                <img src={process.env.PUBLIC_URL + '/assets/img/ourservices/enterpriseAssetDisposition/itassetsremoval.jpeg'} className="rounded-3" alt="IT Asset Audit & Removal" />
                                <div className="card-body">
                                    <h6 className="fw-semibold text-body-emphasis fs-5">IT Asset Audit & Removal</h6>
                                    <p>We enable you to systematically remove retired IT assets from your live environment and ensure all digital data is destroyed.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card border-0">
                                <img src={process.env.PUBLIC_URL + '/assets/img/ourservices/enterpriseAssetDisposition/itassetsremoval.jpeg'} className="rounded-3" alt="IT Asset Audit & Removal" />
                                <div className="card-body">
                                    <h6 className="fw-semibold text-body-emphasis fs-5">IT Asset Audit & Removal</h6>
                                    <p>We enable you to systematically remove retired IT assets from your live environment and ensure all digital data is destroyed.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card border-0">
                                <img src={process.env.PUBLIC_URL + '/assets/img/ourservices/enterpriseAssetDisposition/cod.png'} className="rounded-3" alt="Certification of Destruction" />
                                <div className="card-body">
                                    <h6 className="fw-semibold text-body-emphasis fs-5">Certification of Destruction</h6>
                                    <p>Assured destruction will protect your business’s data from getting into the wrong hands. E-Parisaraa offers a full suite of destruction and electronic recycling services.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card border-0">
                                <img src={process.env.PUBLIC_URL + '/assets/img/ourservices/enterpriseAssetDisposition/ewasterecycling.png'} className="rounded-3" alt="IT Asset Audit & Removal" />
                                <div className="card-body">
                                    <h6 className="fw-semibold text-body-emphasis fs-5">E-Waste Recycling</h6>
                                    <p>Secure on-and-off site data erasure and disk shredding. Supply of video files of destruction for auditing proposes and real-time asset tracking.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AssetDisposition
