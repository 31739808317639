import React from 'react'
import './technicalpapers.css'

const TechnicalPapers = () => {
  return (
    <>
      <div className="container-fluid sec-papers">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <p className='heading'>Papers Published</p>
                            
                            <ol>
                                <li className='mb-4'><a href="https://www.researchgate.net/publication/288282614_E-waste_recycling-best_option_for_resource_recovery_and_sustainable_environment" target='_blank'>E-Waste Recycling-Best Option for Resource Recovery and Sustainable Environment: Parthasarathy P., Keshav A. Bulbule, Anantha Murthy K.S., Research Journal of Chemistry And Environment Vol. 12 (1) March (2008) page no 93</a></li>

                                <li className='mb-4'><a href='https://www.researchgate.net/publication/223092589_Contamination_by_Trace_Elements_at_E-Waste_Recycling_Sites_in_Bangalore_India' target='_blank'>“Contamination by trace elements at e-waste recycling sites, India in Bangalore” - by Nguyen Ngoc Ha, Tetsuro Agusa, Karri Ramu, Nguyen Phuc Cam Tu, Satoko Murata, Keshav A. Bulbule, Peethmbaram Parthasarathy, Shin Takahashi, Annamalai Subramanian, Shinsuke Tanabe published in “Chemosphere” 76 (2009) from Pg. No 9-15.</a></li>

                                <li className='mb-4'><a href="https://www.semanticscholar.org/paper/Organohalogen-and-Metabolite-Contaminants-in-Human-Akifumi-Eguchi/c87065bb0a69f9683b3723528efeff41e4796fdb" target="_blank">Organo halogen and Metabolite Contaminants in Human Serum samples from Indian E-waste Recycling Workers: Akifumi EGUCHI, Kei NOMIYAMA, Annamalai SUBRAMANIAN, Peethambaram PARTHASARATHY, Kesav A. BULBULE,Shin TAKAHASHI and Shinsuke TANABE Center for Marine Environmental Studies (CMES), Published by Ehime University, Interdisciplinary Studies on Environmental Chemistry — Environmental Specimen Bank, pp. 167–174.© by TERRAPUB, 2010.Japan</a></li>

                                <li className='mb-4'><a href="https://pubmed.ncbi.nlm.nih.gov/22717641/" target="_blank">“Different Profiles of anthropogenic and naturally produced organohalogen compounds in serum from residents living near a coastal area and e-waste recycling workers in India”, by Akifumi Eguchi, Kei Nomiyama, Peethambaram Parthasarathy, Keshav A Bulbule, Environment International, 47(2012), 8-16.</a></li>

                                <li className='mb-4'><a href="https://www.sciencepubco.com/index.php/IJSW/article/view/1353" target="_blank">Application of Gold Ore Tailings (GOT) as a source of micronutrients for growth of plants: Swaroop G, Keshav A Bulbule, P Parthasarathy, Yogesh Shivakumar, Ranjith Muniswamy, Reshma Annamati, Priyanka D, International Journal of Scientific World, 1 (3) (2013) page no 68-78</a></li>

                                <li className='mb-4'><a href="https://www.academia.edu/13950695/Methylated_and_unsubstituted_polycyclic_aromatic_hydrocarbons_in_street_dust_from_Vietnam_and_India_Occurrence_distribution_and_in_vitro_toxicity_evaluation" target="_blank">Methylated and unsubstituted polycyclic aromatic hydrocarbons in street dust from Vietnam and India: occurrence, distribution and in vitro toxicity evaluation. Tuyen LH1, Tue NM1, Takahashi S2, Suzuki G3, Viet PH4, Subramanian A5, Bulbule KA6, Parthasarathy P7, Ramanathan A8, Tanabe S5. Environ Pollut 2014 Nov;194:page no 272-80.</a></li>

                                <li className='mb-4'><a href="https://www.ijser.org/onlineResearchPaperViewer.aspx?Environmentally-Sound-Recycling-Technology-of-Scrap-Printed-Circuit-Boards-for-Developing-countries.pdf" target="_blank">Environmentally Sound Recycling Technology of Scrap Printed Circuit Boards for Developing Countries: P.Parthasarathy, Sandip Chatterjee, M.R.P. Reddy, Keshav A. Bulbule, International Journal of Scientific and Engineering Research ,Vol.9 Issue 3 March 2018.</a></li>

                                <li className='mb-4'><a href="http://ivyunion.org/index.php/ajpse/article/view/1170" target="_blank">Characterisation of End of Life Personal Computers for Optimum Resource Recovery: P. Parthasarathy, Anantha Murthy, K.S, Keshav A Bulbule, American Journal of Polymer Science and Engineering, March 2018.</a></li>

                                <li className='mb-4'><a href="https://www.allresearchjournal.com/archives/?year=2018&vol=4&issue=1&part=C&ArticleId=4733" target="_blank">Resource recovery of engineering plastics from E-waste recycling. Int J Appl Res 2018;4(1):148-150.</a></li>

                                <li className='mb-4'><a href="https://www.ajer.org/papers/Vol-8-issue-1/Q0801137145.pdf" target="_blank">Recovery of Rare Earth Elements from Spent Hard Disc Drive Magnets- Urban Mining Suitable for Developing Countries: P. Parthasarathy, and Keshav A.Bulbule, American Journal of Engineering Research(AJER)Vol.8, Issue-1 PP137-145 (2019).</a></li>

                                <li className='mb-4'><a href="https://www.ajer.org/papers/Vol-8-issue-1/Q0801137145.pdf" target="_blank">Book Chapter titled “Resource Sustainability by Electronic Waste Management” published in the book “ Urban Growth and Environmental Issues in India”, Spring Nature Singapore Pte Ltd.2021</a></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default TechnicalPapers


