import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <>
       <div className="container-fluid border-2 border-top">
                <div className="container">
                    <footer className="pt-5">
                        <div className="row">
                            <div className="col-md-6 mb-3 address-sec">
                                <h3>E-Parisaraa Pvt Ltd</h3>

                                <div>
                                    <div className='d-flex'>
                                        <i className="bi-house fs-6 me-2"></i>
                                        <p>No. B, 41/1, 3rd Stage, Maruthi Nagar, Peenya, Bengaluru, Karnataka 560058</p>
                                    </div>
                                    <div className='d-flex'>
                                        <i className="bi-telephone fs-6 me-2"></i>
                                        <p>Phone : 080 2836 0902</p>
                                    </div>
                                    <div className='d-flex'>
                                        <i className="bi-recycle fs-6 me-2"></i>
                                        <p>Plot no. 30-P3, Karnataka Industrial Area Development Board, Sompura Industrial, Madras Bombay Trunk Rd, Karnataka 562111</p>
                                    </div>
                                    <div className='d-flex'>
                                        <i className="bi-telephone fs-6 me-2"></i>
                                        <p>Phone : 099801 47680</p>
                                    </div>
                                    <div className='d-flex'>
                                        <i className="bi-recycle fs-6 me-2"></i>
                                        <p>Plot No. 42A/4, Sy.No.285 (P) and 288 P Hindupur Mandal APIIC Industrial Park Road, Gollapuram, 515211</p>
                                    </div>
                                    <div className='d-flex'>
                                        <i className="bi-telephone fs-6 me-2"></i>
                                        <p>Phone : 09989052687</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-3 footer-links">
                                <h5>Services</h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0">DataCenter Decommission</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0">Enterprise Asset Disposition</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0">Environmental Reporting</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0">Optimised Innovation</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0">Ewaste Recycling</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0">Battery Recycling</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0">Reverse Supply Chain</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0">Product Technology</a></li>
                                </ul>
                            </div>

                            <div className="col-md-3 mb-3">
                                <img src={process.env.PUBLIC_URL + '/assets/img/common/onecertifications.png'} className='w-100' alt="" />
                                <img src={process.env.PUBLIC_URL + '/assets/img/common/twocertifications.png'} className='w-100' alt="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="d-flex flex-column flex-sm-row py-2 justify-content-between">
                                <p className='fw-bolder'>Write & Get details about why and how of complete ewaste handling.</p>
                                <ul className="list-unstyled d-flex mb-0">
                                <li className="ms-3"><a className="link-body-emphasis" href="#" target='_blank'><i className="footer-icons  fa-brands fa-facebook-f "></i></a></li>
                                <li className="ms-3"><a className="link-body-emphasis" href="#" target='_blank'><i className="footer-icons  fa-brands fa-x-twitter "></i></a></li>
                                <li className="ms-3"><a className="link-body-emphasis" href="#" target='_blank'><i className="footer-icons  fa-brands fa-linkedin-in "></i></a></li>
                            </ul>
                            </div>
                        </div>
                        <div className='w-100'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31098.21779430783!2d77.501051!3d13.01801!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d1dc5555555%3A0x1af5a9077298071f!2sE-%20Parisaraa%20Private%20Limited!5e0!3m2!1sen!2sin!4v1718995865881!5m2!1sen!2sin" className='map-footer' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="d-flex flex-column flex-sm-row justify-content-between py-2 border-top">
                            <p className='mb-0'>© 2024 Design And Developed By Phoenix Branding Pvt Ltd | All Rights Reserved</p>
                            <ul className="list-unstyled d-flex mb-0">
                                <li className="ms-3"><a className="link-body-emphasis" href="#"><i className="footer-icons fa-solid fa-arrow-up"></i></a></li>
                            </ul>
                        </div>
                    </footer>
                </div>
            </div>
    </>
  )
}

export default Footer
