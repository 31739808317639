import React from 'react'
import Recycle from '../../components/process/recycle/recycledetails/Recycle'

const WeRecycle = () => {
  return (
    <>
    <Recycle/>
    </>
  )
}

export default WeRecycle
