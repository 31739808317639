import React from 'react'
import Initiatives from '../../components/about/initiatives/Initiatives'

const EhsInitiatives = () => {
  return (
    <>
      <Initiatives/>
    </>
  )
}

export default EhsInitiatives
