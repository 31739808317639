import React from 'react'
import './manageddeployment.css'

const ManagedDeployment = () => {
    return (
        <>
            <div className="container-fluid sec-managed-deployment">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            <p className='hero-title'>Managed Deployment</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid sec-managed-deployment pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h3 className='text-start'>Hassle free Managed Information Technology deployment</h3>
                            <p className='para-normal'>From on-site equipment audits to full rollouts, deployments or IT relocations, E-Parisaraa is a partner for large scale IT infrastructure deployment.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={process.env.PUBLIC_URL + '/assets/img/ourservices/managed-IT-deployment/deployment.jpg'} alt="Managed IT Deployment" className='w-100 rounded-3' />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid sec-managed-deployment py-5 sec-two">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            <h3>First things first, start with a plan</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card border-0">
                                <div className="card-body">
                                    <h6 className="fw-semibold text-body-emphasis fs-5">On-site equipment audit</h6>
                                    <p>E-Parisaraa owns facilities across India. You can leverage this global network and work with people who think and operate the same way you do. This allows you to take advantage of cost efficiencies by buffering stock, ready to fit with your pre-planned schedule or rollout on demand.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="card border-0">
                                <div className="card-body">
                                    <h6 className="fw-semibold text-body-emphasis fs-5">Refresh planning and project management</h6>
                                    <p>E-Parisaraa owns facilities across India. You can leverage this global network and work with people who think and operate the same way you do. This allows you to take advantage of cost efficiencies by buffering stock, ready to fit with your pre-planned schedule or rollout on demand.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="card border-0">
                                <div className="card-body">
                                    <h6 className="fw-semibold text-body-emphasis fs-5">Industry-leading IT deployment expertise by your side</h6>
                                    <p>E-Parisaraa owns facilities across India. You can leverage this global network and work with people who think and operate the same way you do. This allows you to take advantage of cost efficiencies by buffering stock, ready to fit with your pre-planned schedule or rollout on demand.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid my-5 sec-three">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            <h3>Hands-on IT deployment and infrastructure</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card border-0">
                                <div className="card-body">
                                    <h6 className="fw-semibold text-body-emphasis fs-5">Storage and inventory management</h6>
                                    <p>E-Parisaraa owns facilities across India. You can leverage this global network and work with people who think and operate the same way you do. This allows you to take advantage of cost efficiencies by buffering stock, ready to fit with your pre-planned schedule or rollout on demand.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="card border-0">
                                <div className="card-body">
                                    <h6 className="fw-semibold text-body-emphasis fs-5">Managed deployment and installation</h6>
                                    <p>Installing new IT infrastructure - from data-center to client assets - without compromising operations takes an expert team. E-Parisaraa customers can take advantage of our staging and configuration centers. We can network and stage both enterprise and PC machines at scale.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="card border-0">
                                <div className="card-body">
                                    <h6 className="fw-semibold text-body-emphasis fs-5">Large-scale IT relocations</h6>
                                    <p>This service includes full disconnection and reconnection of devices, de-racking and re-racking of servers and infrastructure, management of internal staff moves and changes and secure packaging and transport. E-Parisaraa has the team to do it safely including all the vehicles, tools and equipment that’s required.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManagedDeployment
