import React, { useRef, useEffect } from 'react';
import './wastehandling.css';

const WasteHandling = () => {
    const videoRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (videoRef.current && !videoRef.current.contains(event.target)) {
                videoRef.current.pause();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [videoRef]);

    return (
        <>
            <div className="container-fluid waste-handling-sec-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 mx-auto text-center">
                            <p className='hero-title mb-0'>Mann Ki Baat - E Waste Handling</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid sec-e-waste-handling py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-11 mx-auto">
                            <p>
                                We at E Parisaraa Pvt Ltd are extremely proud and honored to receive recognition from the honorable Prime Minister Sri Narendra Modi in his radio program ‘Mann Ki Baat’. It is a huge achievement for our organization, which has been striving hard to drive the e waste recycling industry.
                            </p>
                            <p>
                                It is no secret that e waste is a major environmental issue in India. This is why, at E Parisaraa, we are dedicated to finding innovative and sustainable solutions to the challenge of e waste management. We believe that this is our responsibility and our privilege to be able to make a difference in the environment.
                            </p>
                            <video
                                ref={videoRef}
                                style={{ width: '100%' }}
                                controls
                                autoPlay
                                loop
                                muted
                            >
                                <source src="assets/video/Man-ki-baat.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <p className='mt-2'>
                                Our team of professionals is constantly working hard to ensure that each and every piece of e waste is collected, sorted, and recycled in an efficient manner. We have set up a network of collection points across India, from where our team collects the e waste. This e waste is then sorted and segregated according to its type. We have established a team of recyclers who go through the process of dismantling and recycling the e waste to its component parts. This ensures that none of the precious resources are wasted and are used for productive purposes.</p>
                            <p>At E Parisaraa, we are continually working towards the goal of making the environment cleaner and greener. We want to ensure that all the e waste is recycled in a responsible manner and that the resources are used in a way that benefits everyone. We are proud to have been recognized by the honorable Prime Minister Sri Narendra Modi in his radio program "Mann Ki Baat". This recognition has further encouraged us to continue our work and strive to make India e waste free.</p>
                            <p className='mb-0'>We are sure that with the help of our team and the government, India can soon become a leader in e waste management. We urge everyone to join us in our mission to make India a cleaner and greener place.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WasteHandling;
