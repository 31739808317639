import React from 'react'
import DataCenterDecommisioning from '../../components/ourservices/datacenterdecommisioning/DataCenterDecommisioning'

const DataCenterDecomm = () => {
  return (
    <>
      <DataCenterDecommisioning/>
    </>
  )
}

export default DataCenterDecomm
