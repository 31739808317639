import React from 'react'
import './eparisara.css'

const EParisara = () => {
    return (
        <>
            <div className="container-fluid sec-e-parisaraa">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <p className='hero-title text-center'>Why E-Parisaraa</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7 mx-auto">
                            <div className='d-flex mb-3'>
                                <div>
                                    <span className='num'>1</span>
                                </div>
                                <div>
                                    <p className='para-normal'>E-Parisaraa is a role Model for the entire country for recycling of scrapped electronic materials using techniques suitable to Indian Conditions which has economic, environmental and social impacts.</p>
                                </div>
                            </div>
                            <div className='d-flex mb-3'>
                                <div>
                                    <span className='num'>2</span>
                                </div>
                                <div>
                                    <p className='para-normal'>E-Parisaraa is India's first E-Waste recycler to obtain both ISO 14001:2004 and OHSAS 18001:2007 certifications. from TUV SUD.</p>
                                </div>
                            </div>
                            <div className='d-flex mb-3'>
                                <div>
                                    <span className='num'>3</span>
                                </div>
                                <div>
                                    <p className='para-normal'>E-Parisaraa has been assigned 'NSIC-D&B-SMERA: SE 2B rating by SME Rating Agency of India Ltd. This rating indicates 'High Performance Capability and Moderate Financial Strength.</p>
                                </div>
                            </div>
                            <div className='d-flex mb-3'>
                                <div>
                                    <span className='num'>4</span>
                                </div>
                                <div>
                                    <p className='para-normal'>E-Parisaraa is India's first E-waste recycler approved from both Central Pollution Control Board (CPCB) and Karnataka State Pollution Control Board (KSPCB).</p>
                                </div>
                            </div>
                            <div className='d-flex mb-3'>
                                <div>
                                    <span className='num'>5</span>
                                </div>
                                <div>
                                    <p className='para-normal'>E-Parisaraa is engaged in recovery, refinement and reuse of gold & silver in gold plating and silver plating of watch pails, pen parts, imitation jewellery, temple items, etc.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 mx-auto">
                            <div className='bg-warning p-4'>
                                <i className="fa-solid fa-circle-info" style={{ fontSize: '50px', color: '#182822', marginBottom: '10px' }}></i>
                                <p className='para-normal'>Besides precious metals such as Gold, Silver and Palladium, E-Parisaraa also recover other metals such as Selenium, Indium, Cobalt, Tantalum and Ruthenium in an environmentally sound methods</p>
                            </div>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-md-5 mx-auto text-center">
                            <img src={process.env.PUBLIC_URL + '/assets/img/ourservices/why-e-parisaraa/e-parisaraa.png'} alt="why-e-parisaraa" />
                            <p className='pt-2 para-normal'>Gold Plated items by Surface Chem Finishers, parent company of E-Parisaraa, using gold recovered from E-waste.</p>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-2 mx-auto">
                            <div className='bg-warning p-4'>
                                <i className="fa-solid fa-circle-info" style={{ fontSize: '50px', color: '#182822', marginBottom: '10px' }}></i>
                                <p className='para-normal'>All together we can proudly say that E-Parisaraa cares about the society and environment and is working for the cleaner environment</p>
                            </div>
                        </div>
                        <div className="col-md-8 mx-auto">
                            <div className='d-flex mb-3'>
                                <div>
                                    <span className='num'>6</span>
                                </div>
                                <div>
                                    <p className='para-normal'>E-Parisaraa is a role Model for the entire country for recycling of scrapped electronic materials using techniques suitable to Indian Conditions which has economic, environmental and social impacts.</p>
                                </div>
                            </div>
                            <div className='d-flex mb-3'>
                                <div>
                                    <span className='num'>7</span>
                                </div>
                                <div>
                                    <p className='para-normal'>E-Parisaraa is India's first E-Waste recycler to obtain both ISO 14001:2004 and OHSAS 18001:2007 certifications. from TUV SUD.</p>
                                </div>
                            </div>
                            <div className='d-flex mb-3'>
                                <div>
                                    <span className='num'>8</span>
                                </div>
                                <div>
                                    <p className='para-normal'>E-Parisaraa has been assigned 'NSIC-D&B-SMERA: SE 2B rating by SME Rating Agency of India Ltd. This rating indicates 'High Performance Capability and Moderate Financial Strength.</p>
                                </div>
                            </div>
                            <div className='d-flex mb-3'>
                                <div>
                                    <span className='num'>9</span>
                                </div>
                                <div>
                                    <p className='para-normal'>E-Parisaraa is India's first E-waste recycler approved from both Central Pollution Control Board (CPCB) and Karnataka State Pollution Control Board (KSPCB).</p>
                                </div>
                            </div>
                            <div className='d-flex mb-3'>
                                <div>
                                    <span className='num'>10</span>
                                </div>
                                <div>
                                    <p className='para-normal'>E-Parisaraa is engaged in recovery, refinement and reuse of gold & silver in gold plating and silver plating of watch pails, pen parts, imitation jewellery, temple items, etc.</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default EParisara
