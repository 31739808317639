import React from 'react'
import videos from './videosgallery.json'
import './gallery.css'
import ImagesGallery from '../../common/imagesgallery/ImagesGallery.jsx'

const Gallery = () => {
  return (
    <>
      <div className="container-fluid sec-gallery">
            <div className="container">
                <div className="row">
                    <div className="col-md-10 mx-auto text-center">
                        <p className="heading">Videos</p>
                    </div>
                </div>
                <div className="row">
                    {videos.map((video, index) => (
                        <div className="col-md-4" key={index}>
                            <iframe
                                src={video.src}
                                title={video.title}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                            ></iframe>
                        </div>
                    ))}
                </div>
                <ImagesGallery/>
            </div>
        </div>
    </>
  )
}

export default Gallery

