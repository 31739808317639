import React from 'react'
import './contactform.css'

const ContactForm = () => {
    return (
        <>
            <div className="container-fluid sec-contact-form">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            <p className='hero-title'>Contact Us</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5 sec-contact-form">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <h3>Contact with us if you need help or just want to say hello</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <form>
                                <div class="row mb-4">
                                    <div class="col">
                                        <input type="text" class="form-control" placeholder="First name" aria-label="First name" />
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" placeholder="Last name" aria-label="Last name" />
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col">
                                        <input type="email" class="form-control" placeholder="Email" aria-label="Email" />
                                    </div>
                                    <div class="col">
                                        <input type="tel" class="form-control" placeholder="Phone" aria-label="Phone" />
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col">
                                        <input type="text" class="form-control" placeholder="Subject" aria-label="Subject" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <textarea class="form-control" id="message" rows="3"></textarea>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactForm