import React from 'react'
import './awards.css'

const Awards = () => {
    return (
        <>
            <div className="container-fluid awards-sec awards-sec-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 mx-auto text-center">
                            <p className='hero-title mb-0'>Awards and Endorsement</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5 awards-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <ul className='mb-0'>
                                <li className="mb-4">”National Award-2010 for outstanding entrepreneurship First Prize by the ministry of MSME, from Her Excellency, The President of India, Dr. Mrs. Pratiba Devisingh Patil”</li>
                                <li className="mb-4">E- Parisaraa is India’s first E-Waste recycler to obtain both ISO 14001:2004 and OHSAS 18001:2007 certifications, from TUV SUD.</li>
                                <li className="mb-4">E-Parisaraa is awarded as “Nehru National Environment” for “Waste Management” `Parisaraa Mitra on 26th January 2005 by “State Environment Protection Organisation”, Karnataka.</li>
                                <li className="mb-4">In recognition of our Managing Director’s contribution to Electroplating industry he has been awarded Best Plater Award 2003 by the SAEST Central for Electrochemical Research Institute, Karaikudi and M.N. Sampath Award 2004 by Electrochemical Society of India, Indian Institute of Science, Bangalore</li>
                                <li className="mb-4">Our Managing Director Mr. P. Parthasarathy is awarded “Utthama Shree Prashasthi” – by Siddaganga Institute, Tumkur.</li>
                                <li className="mb-4">E-Parisaraa was adjudged the First place in “Genesis – The Venture Validation Platform” in EXIMIUS 2010 organized by Indian Institute of Management Bangalore, for the submitted Business plan of Eco-Friendly Recycling of Lighting Devices.</li>
                                <li className="mb-4">E-Parisaraa is winner of Third Prize of National Level Essay Competition on Eco-Ganesha organized by National Referral Centre for Lead Poisoning in India (NRCLPI) and Quality Council of India.</li>
                                <li className="mb-4">E-Parisaraa has won the Frost & Sullivan Excellence Award for the ‘Best Entrepreneurial Company of the year 2009 in the Waste Management Services Vertical.</li>
                                <li className="mb-4">Entrepreneur of the year 2009 by Trade Post, Mumbai, February 2010.</li>
                                <li className="mb-4">Parisara Mithra award 2010.</li>
                                <li className="mb-4">Innovative MSME Project Award- from the Hon’ble Union Minister Padma Vibhushan, Shri Pranab Mukherjee by SIDBI.</li>
                                <li className="mb-4">National Award – 2009 (Special Recognition Award) by Ministry of Micro, Small & Medium Scale Enterprises in recognition of our outstanding performance and efforts in entrepreneurship.</li>
                                <li className="mb-4">CII-ITC Sustainability Award 2010, ‘Certificate of Commendation’ for Significant Achievement on the journey towards Sustainable Development for the first time for a Micro Business Organisations.</li>
                                <li className="mb-4">Technovation Awards 2011 by India Semi Conductor Association (15A) for “Start-up to Watch” – 2011, Bangalore.</li>
                                <li className="mb-4">“Glory of India Award” and Certificate of Excellence in Friendship Banquet in Bangkok-2011.</li>
                                <li className="mb-4">“Excellent Initiative towards E-Waste Management for the Year 2011” by Federation of Indian Export Organisations under Ministry of Commerce , Industry and Government of India “FIEO Telecom Technology Awards 2011” New Delhi.</li>
                                <li className="mb-4">E-Waste Knowledge Fair Prize at WRF 2015.</li>
                                <li className="mb-4">Top 10 E waste Recycling Companies 2022</li>
                            </ul>
                        </div>
                        <div className="col-md-6 text-center">
                            <div>
                                <img src={process.env.PUBLIC_URL + '/assets/img/about/awards/presidentaward.jpeg'} alt="Awards and Endorsement" className='w-100 rounded-3' />
                                <h6 className='mt-2 fw-semibold'>Mangaing Director, Mr P Parthasarthy receveing award from former president</h6>
                            </div>

                            <div className="row d-flex my-4 akign-items-center">
                                <div className="col-md-6">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/about/awards/awardone.jpeg'} alt="Awards and Endorsement" className='w-100 rounded-3' />
                                </div>
                                <div className="col-md-6">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/about/awards/awardtwo.jpg'} alt="Awards and Endorsement" className='w-100 rounded-3' />
                                </div>
                                <h6 className='mt-2 fw-semibold'>National and International Appreciations</h6>
                            </div>

                            <div>
                                <img src={process.env.PUBLIC_URL + '/assets/img/about/awards/appreciation.jpeg'} alt="Awards and Endorsement" className='w-100 rounded-3 border-2' />
                                <h6 className='mt-2 fw-semibold'>National and International Appreciations</h6>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="container-fluid py-5 "  style={{ backgroundColor: 'var(--section-bg2)' }}>
                <div className="container">
                <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            <img src={process.env.PUBLIC_URL + '/assets/img/about/awards/magazine-page.jpg'} alt="Awards and Endorsement" className='w-100 rounded-3' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Awards
