import React from 'react'
import './datacenterdecommisioning.css'

const DataCenterDecommisioning = () => {
    return (
        <>
            <div className="container-fluid sec-data-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            <p className='hero-title mb-0'>Data Center Decommisioning</p>
                            <p className='para-normal'>Achieve Maximum Value Recovery with Expert Data Center Decommissioning</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid my-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={process.env.PUBLIC_URL + '/assets/img/ourservices/dataCenterDecommisioning/datadecom.png'} alt="Data Center Decommisioning" className='w-100' />
                        </div>
                        <div className="col-md-6 sec-data-center">
                            <p className="para-normal">Data Center Decommission requires lot of expertise and secure way of handling the sensitiveness comprising all regulations. Getting rid of data securely is a problem that is faced by many modern organizations, whether they are small or large-scale. E-Parisaraa is known for handling due its expertise from end to end resolution creation.</p>
                            <p className="para-normal">Best practices for secure decommissioning – and uncovering hidden revenue</p>
                            <ul>
                                <li>Sanitize all hardware by fully wiping data.</li>
                                <li>Rigorously track assets to provide a complete chain of custody.</li>
                                <li>Identify drives that cannot be wiped and destroy them onsite.</li>
                                <li>Customize logistics to make decommissioning efficient and effective.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid sec-data-center py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <p className='heading mb-0'>IT Asset Disposition</p>
                            <img src={process.env.PUBLIC_URL + '/assets/img/ourservices/dataCenterDecommisioning/it-asset-disposition.png'} alt="Data Center Decommisioning" />
                            <p className="para-normal">E-Parisaraa is experienced IT asset disposal service provider. The choice of a service provider is one of the most important decisions during the process and doing a little research on potential ITAD service providers will be well worth your while.</p>
                        </div>
                        <div className="col-md-6 mb-0">
                            <p className='heading mb-0'>Data Destruction</p>
                            <img src={process.env.PUBLIC_URL + '/assets/img/ourservices/dataCenterDecommisioning/data-destruction.png'} alt="Data Destruction" />
                            <p className="para-normal">E-Parisaraa assures all the data is wiped. Sensitive hard drives are destructed either at Client premises or at E-Parisaraa recycling facility. Data Destruction certificate is provided for the same.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DataCenterDecommisioning
