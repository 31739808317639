import React from 'react'
import WasteHandling from '../../components/about/ewastehandling/WasteHandling'

const MannKiBaat = () => {
  return (
    <>
      <WasteHandling/>
    </>
  )
}

export default MannKiBaat
