import React, { useState } from 'react';
import './imagesgallery.css';
import images from './imagesgallery.json';

const ImagesGallery = () => {
    const [showCarousel, setShowCarousel] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    const openCarousel = (index) => {
        setShowCarousel(true);
        setSelectedImage(process.env.PUBLIC_URL + images[index].src);
        setCurrentIndex(index);
    };

    const closeCarousel = () => {
        setShowCarousel(false);
        setSelectedImage(null);
        setCurrentIndex(0);
    };

    const nextSlide = () => {
        const nextIndex = (currentIndex + 1) % images.length;
        setSelectedImage(process.env.PUBLIC_URL + images[nextIndex].src);
        setCurrentIndex(nextIndex);
    };

    const prevSlide = () => {
        const prevIndex = (currentIndex - 1 + images.length) % images.length;
        setSelectedImage(process.env.PUBLIC_URL + images[prevIndex].src);
        setCurrentIndex(prevIndex);
    };

    return (
        <>
            <div className="container-fluid sec-images-gallery">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            <p className='heading'>Gallery</p>
                        </div>
                    </div>
                    <div className="row">
                        {images.map((image, index) => (
                            <div className="col-md-4" key={index}>
                                <div className="img-container">
                                    <img
                                        src={process.env.PUBLIC_URL + image.src}
                                        className="d-block w-100"
                                        alt="..."
                                        onClick={() => openCarousel(index)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* Carousel Modal */}
            {showCarousel && (
                <div className="carousel-modal">
                    <span className="close-btn" onClick={closeCarousel}>&times;</span>
                    <img src={selectedImage} alt="carousel-img" className="carousel-image" />
                    <div className="carousel-nav">
                        <span className="nav-btn prev-btn" onClick={prevSlide}>&#8249;</span>
                        <span className="nav-btn next-btn" onClick={nextSlide}>&#8250;</span>
                    </div>
                </div>
            )}
        </>
    );
};

export default ImagesGallery;
