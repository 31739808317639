import React from 'react'
import './services.css'

const Services = () => {
    return (
        <>
            <div className="container-fluid home-services">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-12 mx-auto">
                            <h5>Service and Solutions</h5>
                            <h3>Devoted & Trustworthy Waste Collection Services</h3>
                            <div class="container" id="hanging-icons">
                                <div class="row g-5 row-cols-1 row-cols-lg-3">
                                    <div class="col d-flex align-items-start service-cards">
                                        <div class="icon-square service-icons d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                            <i class="bi-cloud"></i>
                                        </div>
                                        <div>
                                            <h6 class="fw-semibold text-body-emphasis fs-5">Data Center Decommissioning</h6>
                                            <p>Our experienced team handles from planning to decommissioning to disposal, keeping information secure and adhering to best recycling practices.</p>
                                        </div>
                                    </div>
                                    <div class="col d-flex align-items-start service-cards">
                                        <div class="icon-square service-icons d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                            <i class="bi-building"></i>
                                        </div>
                                        <div>
                                            <h6 class="fw-semibold text-body-emphasis fs-5">Enterprise Asset Disposition</h6>
                                            <p>E-Parisaraa is responsible and experienced in secure IT asset disposal, secure data destruction, and data center decommissioning</p>
                                        </div>
                                    </div>
                                    <div class="col d-flex align-items-start service-cards">
                                        <div class="icon-square service-icons d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                            <i class="bi-tree"></i>
                                        </div>
                                        <div>
                                            <h6 class="fw-semibold text-body-emphasis fs-5">Environmental Reporting</h6>
                                            <p>E-Parisaraa provides and publishes environment sustainability reports, Certificate of destruction, Air Quality reports etc.</p>
                                        </div>
                                    </div>
                                    <div class="col d-flex align-items-start service-cards">
                                        <div class="icon-square service-icons d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                            <i class="bi-lightbulb"></i>
                                        </div>
                                        <div>
                                            <h6 class="fw-semibold text-body-emphasis fs-5">Optimized Innovation</h6>
                                            <p>Deep understanding from the expert e waste enthusiast has helped to bring in optimized innovation for end to end e waste handling and recycling.</p>
                                        </div>
                                    </div>
                                    <div class="col d-flex align-items-start service-cards">
                                        <div class="icon-square service-icons d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                            <i class="bi-sliders"></i>
                                        </div>
                                        <div>
                                            <h6 class="fw-semibold text-body-emphasis fs-5">Product Technology</h6>
                                            <p>Several In House sustainable product innovation's for handling e waste and recovering of metals has been taken.</p>
                                        </div>
                                    </div>
                                    <div class="col d-flex align-items-start service-cards">
                                        <div class="icon-square service-icons d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                            <i class="bi-recycle"></i>
                                        </div>
                                        <div>
                                            <h6 class="fw-semibold text-body-emphasis fs-5">Reverse Supply Chain</h6>
                                            <p>E parisaraa has been involved from analyzing the e waste, picking up from the places and recycling. A complete reverse logistic solution is provided.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pb-5">
                        <div className="col-md-12 text-center pt-5">
                            <a className="btn-services">
                                Read More Services
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Services