import React from 'react'
import Dobbaspete from '../../components/certifications/dobbaspete/Dobbaspete'

const Consents = () => {
  return (
    <>
      <Dobbaspete/>
    </>
  )
}

export default Consents
