import React from 'react'
import './environmentalreporting.css'

const EnvironmentalReporting = () => {
  return (
    <>
      <div className="container-fluid sec-environmental-reporting">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            <p className='hero-title'>Environmental Reporting</p>
                            <p className='para-normal'>E-Parisaraa has been actively involved in reporting the ewaste reports with governing authorities of India and concerned organizations. All cycle of ewaste collection, handling and recycling is audited and reported with acurate information and insights.</p>

                            <p className='para-normal'>Our Managing Director : Mr P Parthasarathy, renowned member and scientist for many Governing bodies of handling the waste in sustainable way and creating sustainable environment</p>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default EnvironmentalReporting

