import React from 'react'
import AssetDisposition from '../../components/ourservices/enterpriseassetdisposition/AssetDisposition'

const EnterpriseAsset = () => {
  return (
    <>
      <AssetDisposition/>
    </>
  )
}

export default EnterpriseAsset
