import React, { useState, useEffect } from 'react';
import './management.css';

const Management = () => {
    const [enterpriseCustomers, setEnterpriseCustomers] = useState(0);
    const [ewasteRecycled, setEwasteRecycled] = useState(0);
    const [countersStarted, setCountersStarted] = useState(false);

    useEffect(() => {
        // Function to simulate counting effect
        const countToValue = (targetValue, setStateFunction, speed) => {
            return new Promise(resolve => {
                let count = 0;

                const counter = setInterval(() => {
                    count++;
                    setStateFunction(count);
                    if (count === targetValue) {
                        clearInterval(counter);
                        resolve(); // Resolve the promise when counting is complete
                    }
                }, speed);
            });
        };

        // Function to start both counters
        const startCounters = async () => {
            await Promise.all([
                countToValue(100, setEnterpriseCustomers, 60), // Slower speed for the first counter
                countToValue(1000, setEwasteRecycled, 0) // Faster speed for the second counter
            ]);
            setCountersStarted(true); // Set countersStarted to true when both counters are finished
        };

        // Start the counters only if they haven't been started yet
        if (!countersStarted) {
            startCounters();
        }

        // Clean up function to clear intervals on component unmount
        return () => {
            clearInterval(countToValue);
        };
    }, [countersStarted]); // Ensure useEffect runs again if countersStarted changes

    return (
        <>
            <div className="container-fluid about-us management-sec-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            <p className='hero-title'>About Us</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid about-us py-5">
                <div className="container">
                    <div className="row pb-4">
                        <div className="col-md-11 mx-auto text-center">
                            <h5>Stake Holder's Consultations</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <h3>We are a group of qualified experts</h3>
                            <p>E-Parisaraa continuously engages with all the stakeholders particularly with their <span>esteemed Clients, Government Authorities</span> etc., in all aspects of e-waste recycling right from Legislation to Final Disposal.</p>
                            <div className="row px-5 pt-5">
                                <div className="col-md-6">
                                    <div className='text-center'>
                                        <img src={process.env.PUBLIC_URL + '/assets/img/about/management/customers.webp'} alt="Happy Enterprise" className='about-icon' />
                                        <h3 className={`fw-semibold text-body-emphasis my-2 ${countersStarted ? 'counted' : ''}`}>{enterpriseCustomers}+</h3>
                                        <h6 className='fw-semibold text-body-emphasis fs-5'>Happy Enterprise Customers</h6>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='text-center'>
                                        <img src={process.env.PUBLIC_URL + '/assets/img/about/management/recycle.png'} alt="Happy Enterprise" className='about-icon' />
                                        <h3 className={`fw-semibold text-body-emphasis my-2 ${countersStarted ? 'counted' : ''}`}>{ewasteRecycled}k+</h3>
                                        <h6 className='fw-semibold text-body-emphasis fs-5'>Tons of E waste Recycled</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <img src={process.env.PUBLIC_URL + '/assets/img/about/management/expert-group.jpg'} alt="Expert Group" className='w-100 rounded-3' />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid about-us py-5 management-sec-bg">
                <div className="container">
                    <div className="row pb-3">
                        <div className="col-md-12 text-center">
                            <h5>Stake Holder's Consultations</h5>
                            <h3>We picked best specialists that are suited just for you.</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <div className='d-flex'>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/assets/img/about/management/Dr.-P-Parthasarathy.jpg'} alt="Dr. P Parthasarathy" className='rounded-3' />
                                </div>
                                <div className='ms-4'>
                                    <h4 className='fw-semibold text-body-emphasis'>Dr. P Parthasarathy</h4>
                                    <h6 className='fw-semibold text-body-emphasis mb-3'>Managing Director</h6>
                                    <p className=''>Post Graduate in Chemical Engineering from Indian Institute of Technology, Chennai. And has been awarded Ph.D., for the thesis entitled ‘ECOLOGICALLY EFFICIENT RESOURCE RECOVERY OF ELECTRONIC WASTE FOR INDIAN CONDITIONS’ – by the school of Earth Science and Resource Management, Department of Applied Geology, Kuvempu University during November 2011.</p>
                                    <p className=''>He has over 10 years experience in Electronic Waste Recycling and 30 years of experience in Electroplating and chemical processing of metals with specialization in precious metals. He has been working on metal recovery and published a few scientific papers and patents. In recognition his contribution to the industry he has been awarded Best Plater Award 2013 by the SAEST Central Electro Chemical Research Institute, Karaikudi and M.N. Sampath Award 2004 by Electro Chemical Society of India, Indian Institute of Science, Bangalore.</p>
                                    <p className=''>He was associated as a technical consultant for a Singapore based MNC, engaged in recycling and recovery of e-waste. He is credited with establishing a few processes for them, and is also assisting them to source equipments, machinery from India besides recruiting and training of technical / engineering personal for them. In-fact this experience promoted him to start E-Waste recycling in India.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 px-5">
                            <img src={process.env.PUBLIC_URL + '/assets/img/about/management/quote-top.webp'} alt="quote-top" className='rounded-3' />
                            <p>In addition, he is member of Professional Bodies such as:</p>
                            <ul>
                                <li>Life member of Metal Finishers Association of India.</li>
                                <li>Vice President of Electro Chemical Society of India, MSc Bangalore.</li>
                                <li>Vice President & Zero Waste Society of India, IISc Bangalore.</li>
                                <li>Member of Society for Advancement of Electrochemical Science and Technology, Karaikudi</li>
                                <li>Member of American Electroplaters Surface Finishers, USA</li>
                                <li>Member of MTD-29 Metal finishing sub-committee of BIS.</li>
                                <li>Member for the task force constituted for E-Waste by Ministry of Environment and Forest, GOI.</li>
                            </ul>

                        </div>
                        <div className="col-md-7 mt-4">
                            <div className='d-flex'>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/assets/img/about/management/Mr.-P-Harishankar.jpeg'} alt="Dr. P Harishankar" className='rounded-5' />
                                </div>
                                <div className='ms-4'>
                                    <h4 className='fw-semibold text-body-emphasis'>Mr. P Harishankar</h4>
                                    <h6 className='fw-semibold text-body-emphasis mb-3'>Executive Director</h6>
                                    <p className=''>A Master's graduate, MS in Environmental Engineering and Science from Clemson University USA. An expert advisor and Environment enthusiast.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Management;
