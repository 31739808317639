import React from 'react'
import Policies from '../../components/about/policies/Policies'

const PoliciesAndMission = () => {
  return (
    <>
      <Policies/>
    </>
  )
}

export default PoliciesAndMission
