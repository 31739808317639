import React from 'react'
import Hindupur from '../../components/certifications/hindupur/Hindupur'

const Hindupurr = () => {
  return (
    <>
      <Hindupur/>
    </>
  )
}

export default Hindupurr
