import React from 'react'
import './datadestruction.css'

const DataDestruction = () => {
    return (
        <>
            <div className="container-fluid sec-data-erasure">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            <p className='hero-title'>Data Erasure and Destruction</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid sec-data-erasure pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h3 className='text-start'>How We Do It</h3>
                            <p>Totally secure and without any compromises and nil risks. From onsite hard drive shredding, puncturing and degaussing to data erasure. Here is our wide range of onsite data destruction services.</p>
                            <div>
                                <a href="" type="button" className="btn-data-destruction">Get In Touch</a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={process.env.PUBLIC_URL + '/assets/img/ourservices/dataerasuredesrruction/datadestruction.png'} alt="Data Erasure and Destruction" className='w-100 rounded-3 border bg-success' />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid my-5 sec-data-erasure">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            <h3>Data destruction & Hard drive shredding from E-Parisaraa</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div class="card border-0">
                                <div class="card-body">
                                    <h6 class="fw-semibold text-body-emphasis fs-5">Onsite degaussing</h6>
                                    <p>The process starts with an engineer delivering degaussing equipment to your site. Onsite degaussing service applies an electromagnetic pulse almost twice the level of the coercivity of today's disk drives, to erase all information on a data storage device. The hard drive is rendered inoperable during this process and cannot be reused.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div class="card border-0">
                                <div class="card-body">
                                    <h6 class="fw-semibold text-body-emphasis fs-5">Onsite puncturing</h6>
                                    <p>The process of puncturing SSDs is slightly different from shredding. At your location, we place drives into machinery containing a press that punctures multiple pins into the hard drive and the chips contained within them which completely annihilates the data contained. Once the memory chips are destroyed following the puncturing process, the drive is littered with holes.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-4">
                        <div class="card border-0">
                                <div class="card-body">
                                    <h6 class="fw-semibold text-body-emphasis fs-5">Onsite data erasure that's above and beyond</h6>
                                    <p>Expert technicians arrive onsite and go through our regimented operating procedures for data erasure. This process achieves a data overwrite which exceeds the NIST 800-88 standard and ensures 100% of sectors are overwritten. Drives with damaged sectors that cannot be overwritten are removed and shredded.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-4">
                        <div class="card border-0">
                                <div class="card-body">
                                    <h6 class="fw-semibold text-body-emphasis fs-5">Onsite hard drive shredding</h6>
                                    <p>At your location, memory storage devices can be shredded in line with your security policies (or we can advise on this too), before being cataloged and certified as destroyed. This can include all kinds of storage devices like spindle drives, SSD drives, USB drives, SIM card, SD and MicroSD cards. Waste is then removed and goes into recycling.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DataDestruction
