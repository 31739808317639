import React from 'react'
import SupplyChain from '../../components/ourservices/supplychain/SupplyChain'

const RSC = () => {
  return (
    <>
      <SupplyChain/>
    </>
  )
}

export default RSC
