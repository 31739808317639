import React from 'react'
import './hero.css'

const Hero = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 px-0">
                        <div id="homecarousel" className="carousel slide">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#homecarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#homecarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#homecarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/home/hero/slider1.jpg'} className="d-block w-100" alt="..." />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>State of The Art Recycling Facility</h5>
                                        <p>Assuring all safety aspects</p>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/home/hero/slider2.jpg'} className="d-block w-100" alt="..." />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>Awarded by Industry Outlook</h5>
                                        <p>One of the TOP 10 E-waste recycling companies</p>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/home/hero/slider3.jpg'} className="d-block w-100" alt="..." />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>Empowering Women and Rural INDIA</h5>
                                        <p>Most of team are well trained and enabled</p>
                                    </div>
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#homecarousel" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#homecarousel" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero
