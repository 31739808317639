import React from 'react'
import ManagedDeployment from '../../components/ourservices/manageddeployment/ManagedDeployment'

const ManagedITDeployment = () => {
  return (
    <>
      <ManagedDeployment/>
    </>
  )
}

export default ManagedITDeployment
