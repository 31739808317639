import React from 'react'
import EnvironmentalReporting from '../../components/ourservices/envreporting/EnvironmentalReporting'

const ER = () => {
  return (
    <>
      <EnvironmentalReporting/>
    </>
  )
}

export default ER
