import React from 'react'
import Management from '../../components/about/management/Management'

const About = () => {
  return (
    <>
      <Management/>
    </>
  )
}

export default About
