import React from 'react'
import TechnicalPapers from '../../components/paperpublished/technicalpapers/TechnicalPapers'

const PapersPublished = () => {
  return (
    <>
      <TechnicalPapers/>
    </>
  )
}

export default PapersPublished
