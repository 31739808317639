import React from 'react'
import './policies.css'

const Policies = () => {
    return (
        <>
            <div className="container-fluid policies-sec policies-sec-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 mx-auto text-center">
                            <p className='hero-title mb-0'>Policies and Mission</p>
                            <h6>Policies of E-Parisaraa are Non-incineration disposal methods and minimum landfill to fulfill all compliance and requirements. We recycle E-Waste in the most environmentally friendly manner and adhere to minimum landfill policy.</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid policies-sec py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3>Value Statement</h3>
                            <ul className='mb-0'>
                                <li className="mb-3">E-Parisaraa exhibit LEADERSHIP as a company. We are first Indian E-Waste recvelers with government approvals, ISO 14001:2004 and OHSAS 18001:2007 certifications. E-Parisaraa’s leadership is found in innovative and in-house developed technologies suitable to Indian conditions, sound management of E-Waste and hazardous materials with 99% recycling efficiency. We add value to waste materials.</li>

                                <li className="mb-3">We take responsibility for E-Waste recycling and disposal. We have a bench mark of 99% recycling. Our services will be “best in class” with respect to collection and disposal of E-Waste. We deliver excellent services and strive for continuous improvement and respond vigorously to changes.</li>

                                <li className="mb-3">E-Parisaraa considers E-Waste as a Geological friend of 21st century for urban mining of resources to the maximum and minimum landfill and pollution. We are committed to protect and save environment. We value environment and mother Earth.</li>

                                <li className="mb-3">We are also employee focused. We value their skill, hard work, honesty and integrity. We respect our employees and cares for them by saying safety first, work next.</li>

                                <li className="mb-3">We respect our customers and are dedicated to serve them, at all times. We strive to reach customers expectations with our services. We respect our customers and serve them to their satisfaction. We work hand in hand with our customers and enjoy working together as a team, helping each other for sustainable growth of the company.</li>

                                <li className="mb-0">We consider our downstream vendors as important as our customers. We work with them and also help them to upgrade their processes, system requirements to meet our compliances.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5 sec-mission">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h3>Our Mission</h3>
                            <ul>
                                <li className="mb-3">We consider E-Waste not as waste, but a resource for recovery in an environmental friendly manner to protect environment.</li>
                                <li className="mb-3">Our motto is 3 R’s Reduce. Reuse and Recycle, through continuous implementations and innovations in recycling technology.</li>
                                <li className="mb-3">Our country, needs simpler cost effective technologies with maximum resource recovery of metals, plastic and glass by recycling electronic waste in environmental friendly way.</li>
                                <li className="mb-3">E-Parisaraa has taken lot of steps and measures for conservation of natural resource like Energy, water etc. We have been using solar power for charging of batteries used in dismantling tools. Rain water is used for gardening.</li>
                            </ul>
                            <h6 className='fw-semibold text-white fs-5 mb-3'>Moreover our facility building is constructed eco friendly with cost efficiency :</h6>
                            <ul>
                                <li className="mb-3">Soil stabilized bricks made at site without the use of burnt bricks.</li>
                                <li className="mb-3">Granite dust used as a substitute for river sand.</li>
                                <li className="mb-3">Granite polishing dust used for refilling the plinth. Waste carbide lime is used for white washing.</li>
                                <li className="mb-3">Light weight thermocol (Blown poly-styrene) concrete blocks for roofing which reduces consumption of steel and concrete, also gives sound insulation, thermal insulation etc.</li>
                                <li className="mb-3">Scrap metal sheets from CPU cabinets are used for gates.</li>
                                <li className='mb-0'>Unused obsolete PCB’s are used as laminates for doors.</li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <img src={process.env.PUBLIC_URL + '/assets/img/about/policiesAndMission/mission.jpg'} alt="Safety devices" className='w-100 rounded-3' />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid policies-sec py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-3">
                        <img src={process.env.PUBLIC_URL + '/assets/img/about/policiesAndMission/healthandsafty.jpeg'} alt="Safety devices" className='rounded-3' />
                        </div>
                        <div className="col-md-9">
                            <h3>Our Environment Health and Safety Policy</h3>
                            <p className='mb-0'>E-Parisaraa management believes that the approaches towards protecting environment, securing health and ensuring safety of its employees are of utmost significance. We are totally dedicated and committed to conduct e-waste management in an environmentally responsible manner with good health and safety practices and thereby conserving the environment and making safe, healthy and accident free work place.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-5 parallax-vision policies-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-light text-center">
                            <div className="" >
                                <h3>Vision Statement</h3>
                                <h6 className='fw-semibold text-body-emphasis fs-5'>To become a world-class facility and provide top-class services and solutions for E-Waste recycling, to help mother earth to harvest maximum resources.</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid policies-sec py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3>WE AIM TO</h3>
                            <ul>
                                <li className="mb-3">Become a centre of excellence for electronic waste recycling.</li>
                                <li className="mb-3">Recover maximum resources from electronic waste and minimize landfill.</li>
                                <li className="mb-3">Adopt cleaner technologies and best practices and strive for continual improvement.</li>
                                <li className="mb-3">Adopt measures for the prevention of air, water & soil pollution.</li>
                                <li className="mb-3">Provide safe and healthy working conditions to all persons working under the control of our organization by providing adequate facilities.</li>
                                <li className="mb-3">Committed to prevent injury, and ill health and strive for continual improvement in overall occupational health & safety performance.</li>
                                <li className="mb-3">Comply with all applicable Environmental and Health and Safety legislation and other requirement.</li>
                                <li className="mb-3">Equip the employees with the information, instruction, training supervision and proper PPE’s to ensure Environment awareness and also their health and safety.</li>
                                <li className="mb-3">Conduct audits, mock drills and periodic health monitoring to ensure compliance with Health & Safety management requirement.This policy will be communicated to all the persons working under the control of organizations, interested parties and shall be regularly reviewed.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Policies
