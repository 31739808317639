import React from 'react'
import Awards from '../../components/about/awards/Awards'

const AwardsEndorsement = () => {
  return (
    <>
      <Awards/>
    </>
  )
}

export default AwardsEndorsement
