import React from 'react'
import './media.css'

const Media = () => {
    return (
        <>
            <div className="container-fluid media-sec-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 mx-auto text-center">
                            <p className='hero-title'>In Media</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid sec-media py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-11 mx-auto">
                            <h3>Links to Articles:</h3>
                            <p>Article on E-waste titled as Bold Pursuits on E-Parisaraa by Brain Taylor, the author is editor of the international magazine Recycling Today Global Edition.</p>
                            <div>
                                <a href='https://www.recyclingtoday.com/article/rtge0714-eparisaraa-electronics-recycling-profile/' type="button" className='read-more-btn'>Read More</a>
                            </div>
                            <h3 className='mt-5'>Media Telecast:</h3>
                            <p>Many world class channels ave telecasted short films on E-Parisaraa and on its unique process adopted for recycling of e-waste .</p>
                            <h6 class="fw-semibold text-body-emphasis fs-5 mb-3">Some of the channels are:</h6>
<ul>
                            <li className=' mb-2'>DD National network</li>
                            <li className=' mb-2'>CNN-IBN</li>
                            <li className=' mb-2'>Ajtak</li>
                            <li className=' mb-2'>CNN-Hong Kong</li>
                            <li className=' mb-2'>NDTV Kasturi Channel</li>
                            <li className=' mb-2'>ETV</li>
                            <li className=' mb-2'>UTV</li>
                            <li className=' mb-2'>NHK-Japan</li>
                            </ul>
                            <h3 className='mt-5'>Media articles on E-Parisaraa:</h3>
                            <p class="fw-semibold text-body-emphasis fs-5">Number of articles has been published in news papers, magazines and journals. Some details of such articles are mentioned below.</p>

                            <ul>
                                <li className=' mb-3'>India: A dumping ground for e-waste Bibhu Ranjan Mishra in Bangalore, BS, August 15, 2006</li>
                                <li className=' mb-3'>Managing E-Waste without harming environment Anuradha Sharma, The Hindu, Monday, April 03,2006</li>
                                <li className=' mb-3'>India's IT firms face new challenge in e-waste disposal Channel newsasia, 08 December 2005</li>
                                <li className=' mb-3'>A circuit to give tube light a long life Staff reporter, The Hindu, Karnataka, 13/05/2005</li>
                                <li className=' mb-3'>An eco-friendly unit to handle e-waste in IT hub Time Out, Sunday, April 10 2005</li>
                                <li className=' mb-3'>New Indian "graveyard" to recycle precious metal from electronics GMT, World Gold Council, 01 April 2005</li>
                                <li className=' mb-3'>City learns to treat e-waste Amit, Deccan Herald, Saturday, May 6, 2006
                                </li>
                                <li className=' mb-3'>Central legislation to regulate e-waste likely BVT news, Bangalore Vijay Times, Thursday 4 May 2006</li>
                                <li className=' mb-3'>Wealth from all that waste Anand Sankar, the Hindu, Monday, June 26, 2006</li>
                                <li className=' mb-3'>E-waste unit near Bangalore awaiting Govt. Approval Vidyashree Amaresh, The Hindu, May 26 2005</li>
                                <li className=' mb-3'>IT Companies sign pact on e-waste recycling Staff reporter, The Hindu, Friday, May 13, 2005</li>
                                <li className=' mb-3'>India's "silicon valley" to get e-waste treatment plant Subash Chandra N 5, Vijay Times , Bangalore, Monday 16th August 2004</li>
                                <li className=' mb-3'>ECG solutions to E-Waste Kanak Hirani Nautiyal, The Times of India. Wednesday, 7th July, 2004</li>
                                <li className=' mb-3'>Bangalore to have green E-Waste treatment plant Deccan Herald, Wednesday, March 17,2004</li>
                                <li className=' mb-3'>A world of E-Waste Prince Frederick. The Hindu, Chennai Metro Plus, Tuesday,3rd April 2007</li>
                                <li className=' mb-3'>Govt says it has 148 e-waste recyclers, but only six exist.</li>
                                <li className=' mb-3'>$3mn project to train 3L unorganized e-waste dealers</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Media
