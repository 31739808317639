import React from 'react'
import Media from '../../components/about/media/Media'

const InMedia = () => {
  return (
    <>
      <Media/>
    </>
  )
}

export default InMedia
