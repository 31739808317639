import React from 'react'
import './about.css'

const About = () => {
    return (
        <>
            <div className="container-fluid home-about">
                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-12 mx-auto text-center">
                            <h5>Welcome to E-Parisaraa</h5>
                            <h3>Providing Trusted, Competitive And Reliable Business Waste Collection</h3>
                            <p>E-Parisaraa Pvt. Ltd, India's first Government authorized electronic waste recycler started operations from September 2005,is engaged in handling, recycling and reusing of Waste Electrical and Electronic Equipment (WEEE) in eco friendly way. The initiative is to aim at reducing the accumulation of used and discarded electronic and electrical equipment’s, which most end up in landfills or partly recycled in a unhygienic conditions by backyard recyclers and then partly thrown into waste streams damaging the environment.The objective of E-Parisaraa is to create an opportunity to transfer waste into socially and industrially beneficial raw materials like valuable metals, plastics and glass using simple, cost efficient, home grown, environmental friendly technologies suitable to Indian Conditions.</p>
                            <div className="d-flex gap-3 w-100 justify-content-center">
                                <a type="button" className="btn-cont">Contact Us</a>
                                <a type="button" className="btn-know-more">Know More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About