import React from 'react'
import './branchlocation.css'

const BranchLocation = () => {
    return (
        <>
            <div className="container-fluid sec-branch-location">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            <p className='hero-title mb-0'>Branch Locations</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5 sec-branch-contact">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            <h3>Our Branch Locations & Contact Details</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card border-0">
                                <div className="card-body">
                                    <h6 className="fw-semibold text-body-emphasis fs-5">Bangalore</h6>
                                    <p>E-PARISARAA PVT. LTD.</p>
                                    <p>NO. 41/1, III STAGE, PEENYA INDUSTRIAL ESTATE, BANGALORE – 560058</p>
                                    <p>Contact Person: <span>Mr. Virender Kaul</span></p>
                                    <p>Ph: 080 – 28360902</p>
                                    <p>Email: recycle@ewasteindia.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card border-0">
                                <div className="card-body">
                                    <h6 className="fw-semibold text-body-emphasis fs-5">Hindupur</h6>
                                    <p>E-PARISARAA PVT. LTD.</p>
                                    <p>Plot No. 42A/4, Sy.No.285 (P) and 288 P Hindupur Mandal APIIC Industrial Park Road, Gollapuram, 515211</p>
                                    <p>Contact Person: <span>Mr. Balakrishan</span></p>
                                    <p>Ph: 9989052687</p>
                                    <p>Email: recycleap.ewaste@gmail.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card border-0">
                                <div className="card-body">
                                    <h6 className="fw-semibold text-body-emphasis fs-5">Chennai</h6>
                                    <p>E-PARISARAA PVT. LTD.</p>
                                    <p>PLOT NO.150, PERUNGUDI INDUSTRIAL ESTATE, CHENNAI – 600 096, India.</p>
                                    <p>Contact Person: <span>Mr. Tharak Ram</span></p>
                                    <p>Ph: 09884337519</p>
                                    <p>Email: chennai@ewasteindia.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-4">
                            <div className="card border-0">
                                <div className="card-body">
                                    <h6 className="fw-semibold text-body-emphasis fs-5">Mumbai</h6>
                                    <p>M/S.E-PARISARAA PVT. LTD.</p>
                                    <p>802/8, BLD:E/15-B, GALA:8, HARIHAR CORPORATION, DAPODE VILLAGE, THANE, BHIWANDI- 421302.</p>
                                    <p>Contact Person: <span>Mr. Dinesh Kumar</span></p>
                                    <p>Ph: 07875262623</p>
                                    <p>Email: bhiwandi@ewasteindia.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-4">
                            <div className="card border-0">
                                <div className="card-body">
                                    <h6 className="fw-semibold text-body-emphasis fs-5">Gurgaon</h6>
                                    <p>E-PARISARAA PVT. LTD.</p>
                                    <p>D 83 Udyog Vihar Phase 6 SECTOR-37,GURGAON – 122001 HARYANA, India.</p>
                                    <p>Contact Person: <span>Mr. Manoj Kandpal</span></p>
                                    <p>Ph: 07838295852</p>
                                    <p>Email: manojkandpal@ewasteindia.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-4">
                            <div className="card border-0">
                                <div className="card-body">
                                    <h6 className="fw-semibold text-body-emphasis fs-5">Kolkata</h6>
                                    <p>E-PARISARAA PVT. LTD.</p>
                                    <p>No.20/3, D. D. Mondal Ghat Road, P.O. Dakshineswar, P.S. Belghoria, District North 24 – Parganas, Kolkata – 700 076, India.</p>
                                    <p>Contact Person: <span>Mr. Somanath Ganguly</span></p>
                                    <p>Ph: 08961761298</p>
                                    <p>Email: kolkata@ewasteindia.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BranchLocation
