import React from 'react'
import OptimizedInnovation from '../../components/ourservices/optimizedinnovation/OptimizedInnovation'

const OptimizeInnov = () => {
  return (
    <>
      <OptimizedInnovation/>
    </>
  )
}

export default OptimizeInnov
