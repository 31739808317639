import React from 'react'
import './benifits.css'

const Benifits = () => {
    return (
        <>
            <div className="container-fluid benifits-sec-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 mx-auto text-center">
                            <p className='hero-title mb-0'>BENIFITS OF RECYCLING</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid sec-benifits py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-11 mx-auto">
                            <h3>Seven Benefits of Recycling</h3>
                            <ul>
                                <li className='mb-3'>Recycling Helps Protect The Environment.</li>
                                <li className='mb-3'>Recycling Helps Conserve Limited Resources.</li>
                                <li className='mb-3'>Recycling Promotes Energy Efficiency.</li>
                                <li className='mb-3'>Recycling Helps Build A Strong Economy.</li>
                                <li className='mb-3'>Recycling Creates Jobs.</li>
                                <li className='mb-3'>Recycling Builds Community.</li>
                                <li className='mb-3'>Recycling Can Be Financially Rewarding.</li>
                            </ul>

                            <p className='mt-5 '><span>Recycling is a process - </span>A series of activities, if you will, that includes: the collection and sorting of waste materials, the processing of these materials to produce brand new products, and the purchase and use of these new products by consumers.</p>

                            <p className=''>Recycling is more optimized and efficient way we practice the three R's of waste management: <span>REDUCE. REUSE. RECYCLE.</span></p>

                            <p className=''>Reducing waste that otherwise gets carted off to the recycling centers or landfills is achieved through an intentional decrease in our purchases and consumption, composting of organic waste, and flat refusal to use disposable items like polystyrene and plastic bags. Reusing materials serve to lengthen a particular item’s usage. Examples of this are: repurposing glass bottles into artistic lamp shades, giving your old cell phones to family or friends for reuse, and upcycling street trash bins into community swimming tubs.</p>

                            <p>But, why recycle? Why go through all the trouble of recycling your garbage? How does recycling benefit us and the environment?</p>

                            <p>Let's review the benefits of recycling.</p>



                            <h3 className='mt-5'>Recycling Helps Protect The Environment</h3>
                            <p>Recycling sharply reduces the amount of waste that gets deposited in our landfills or burned in incinerator plants. Engineered landfills in most cities are designed to contain toxic chemicals leaking from decaying solid waste from reaching our water systems. But, for how long? Already, we re getting reports of dangerous chemicals contaminating water supplies in some cities. Burning solid waste for electricity may be efficient, but we pay the price in terms of increased carbon dioxide and other greenhouse gas emissions.</p>

                            <h3>Recycling Helps Conserve Limited Resources</h3>
                            <p>To put this benefit in proper perspective, let's consider this statement from the Pennsylvania Department of Environmental Protection: “By recycling over 1 million tons of steel in 2004, Pennsylvanians saved 1.3 million tons of iron ore, 718,000 tons of coal, and 62,000 tons of limestone. Through recycling newsprint, office paper and mixed paper, we saved nearly over 8.2 million trees.”</p>

                            <p>Resources like oil and precious metals bold, silver, bauxite, copper, etc. are all finite resources that will be exhausted, sooner or later. Cell phone and computer manufacturers, like Dell and Apple, recognize the need for a steady supply of raw materials - most are active in buy-back programs to recycle materials from used products.</p>



                            <h3 className='mt-5'>Recycling Promotes Energy Efficiency</h3>
                            <p>Recycling is far more efficient, in terms of energy consumption, than producing something out of fresh raw material. Done on a nationwide scale, this could lead to significant reduction in our energy. costs. The energy required to extract, process, and transport metal from a mine to a refinery is obviously much greater than what's required to recycle metal from used products - costs more energy to manufacture a brand new aluminum can from fresh material than to make 21 cans out of recycled materials!</p>

                            <h3>Recycling Helps Build A Strong Economy</h3>
                            <p>Every cost-reduction, energy efficiency, materials conservation, and job generation benefit of recycling adds up to help build a strong economy for our country. Recycling, done on a country-wide scale. has a huge positive impact on the economy. There was dip in the price of recyclables last year when the financial crisis started, but it is testimony to the resiliency of this industry that prices are no. 1. back to pre-crisis levels - a recovery that's well ahead than most other industries. Jobs are being generated and city and town governments are enjoying huge savings in electricity, garbage collection, and landfilling costs.</p>

                            <h3>Recycling Creates Jobs</h3>
                            <p>Recycling generates more jobs than landfilling or incinerating waste. That's a benefit we cant lose sight of, in this time of recession and high unemployment rate. Let's consider the disposal of 10 - 100 tons of solid waste: burning it for electricity will create 1 job; collecting and dumping this on a landfill will create jobs; processing the caste for recyling will generate 36 jobs!</p>



                            <h3 className='mt-5'>Recycling Builds Community</h3>
                            <p>People band together and build communities around common causes. issues, and advocacies. Recycling is no different. In many neighborhoods and cities across the country, we see concerned citizens working together in recycling initiatives, environment lobby groups, and free recycling groups. If you're new to recycling or environmental advocacy, go find a local group to work with. Staying the course is more fun and rewarding when you have other enthusiasts cheering you on.</p>

                            <h3 className=''>Recycling Can Be Financially Rewarding</h3>
                            <p>If you just want to make money to get by in these hard times or start a home business, recycling is a profitable option. Its relatively easy and inexpensive to start a home-based recycling business. You just need to plan on what material (cell phone, paper, or metals, etc.) you intend to collect, plan storage, contact the recycling plant for pricing, and you’re set to start collecting recyclables and reselling these to the recycling facility at a decent profit. The large recycling giants in the US all started as home businesses years ago you can do it, too those guys just recognized the huge potential of this business well ahead of the crowd.</p>

                            <p className='mb-0'>The benefits of recycling to each of us, to society. and to the environment are our compelling reasons Why we recycle. For many of us, recycling has become second nature – a way of life. It’s a small but extremely vital component of environmental protection – without recycling. All our efforts to protect the planet will be less effective, even futile. Let’s all continue recycling.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Benifits

