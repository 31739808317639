import React from 'react'
import { HashRouter as Router, Routes, Route, HashRouter } from "react-router-dom"
import './App.css'
import ScrollToTop from './ScrollToTop';
import Navbar from './components/common/header/bottomheader/Navbar';
import Home from './pages/home/Home';
import About from './pages/management/About';
import EhsInitiaves from './pages/ehsinitiatives/EhsInitiatives'
import InMedia from './pages/inmedia/InMedia';
import MannKiBaat from './pages/ewastehandling/MannKiBaat';
import Benefits from './pages/benefits/Benefits';
import WeRecycle from './pages/werecycle/WeRecycle';
import GalleryVI from './pages/gallery/GalleryVI';
import PapersPublished from './pages/paperspublished/PapersPublished';
import Consents from './pages/dobbaspete/Consents';
import Hindupurr from './pages/hindupur/Hindupurr';
import ER from './pages/environreporting/ER';
import OptimizeInnov from './pages/optimizedinnovation/OptimizeInnov';
import ProductTech from './pages/producttechnology/ProductTech';
import RSC from './pages/reversesupplychain/RSC';
import WhyEparisaraa from './pages/whyeparisaraa/WhyEparisaraa';
import DataCenterDecomm from './pages/datacenterdecommisioning/DataCenterDecomm';
import EnterpriseAsset from './pages/enterpriseassetdisposition/EnterpriseAsset';
import ManagedITDeployment from './pages/manageddeployment/ManagedITDeployment';
import Footer from './components/common/footer/Footer';
import DataErasureDestruction from './pages/dataerasuredestruction/DataErasureDestruction';
import GetInTouch from './pages/contactform/GetInTouch';
import BranchLocation from './components/branchlocation/BranchLocation';
import AwardsEndorsement from './pages/awards/AwardsEndorsement';
import PoliciesAndMission from './pages/policiesandmission/PoliciesAndMission';



const App = () => {
  return (
    <>
      <HashRouter>
        <ScrollToTop/>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/in-media" element={<InMedia/>} />
          <Route path="/about" element={<About/>}/>
          <Route path="/awards-and-endorsement" element={<AwardsEndorsement/>}/>
          <Route path="/mann-ki-baat-e-waste-handling" element={<MannKiBaat/>} />
          <Route path="/benifits-of-recycling" element={<Benefits/>}/>
          <Route path="/we-recycle" element={<WeRecycle/>}/>
          <Route path="/technical-papers" element={<PapersPublished/>}/>
          <Route path="/gallery" element={<GalleryVI/>}/>
          <Route path="/consents" element={<Consents/>}/>
          <Route path="/certifications-hindupur" element={<Hindupurr/>}/>
          <Route path="/environmental-reporting" element={<ER/>}/>
          <Route path="/data-center-decommisioning" element={<DataCenterDecomm/>}/>
          <Route path="/enterprise-asset-disposition" element={<EnterpriseAsset/>}/>
          <Route path="/managed-deployment" element={<ManagedITDeployment/>}/>
          <Route path="/data-destruction" element={<DataErasureDestruction/>}/>
          <Route path="/optimized-innovation" element={<OptimizeInnov/>}/>
          <Route path="/product-technology" element={<ProductTech/>}/>
          <Route path="/reverse-supply-chain" element={<RSC/>}/>
          <Route path="/why-e-parisara" element={<WhyEparisaraa/>}/>
          <Route path="/contact" element={<GetInTouch/>}/>
          <Route path="/branch" element={<BranchLocation/>}/>
          <Route path="/ehs-initiatives" element={<EhsInitiaves/>}/>
          <Route path="/our-policies-and-mission" element={<PoliciesAndMission/>}/>
        </Routes>
        <Footer/>
      </HashRouter>


    </>
  )
}

export default App
