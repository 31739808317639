import React from 'react'
import Gallery from '../../components/paperpublished/gallery/Gallery'

const GalleryVI = () => {
  return (
    <>
      <Gallery/>
    </>
  )
}

export default GalleryVI
