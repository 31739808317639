import React from 'react'
import DataDestruction from '../../components/ourservices/datadestruction/DataDestruction'

const DataErasureDestruction = () => {
  return (
    <>
      <DataDestruction/>
    </>
  )
}

export default DataErasureDestruction
