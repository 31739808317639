import React from 'react'
import './recycle.css'

const Recycle = () => {
  return (
    <>
      <div className="container-fluid recycle-sec-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-9 mx-auto text-center">
              <p className='hero-title mb-0'>RECYCLE</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid sec-recycle py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-11 mx-auto">
              <h3>WE RECYCLE</h3>
              <p>We Recycle end of Life, Obsolete, Discarded Electronics and Electrical Equipments like</p>
              <p className='mb-0'>Computers, Monitors, CPUs, Peripherals, CRT's, Servers, Printers, Plotters, Fax Machines, Scanners, Copiers, Mother boards, Printed Circuit Boards, Laptops, CDs, Floppies, Tapes, Cartridges, Telephones, Cell phones, Telecom Equipments, TVs, Audio & Video, Dry cells, Lithium Batteries, Fluorescent & CFL Lamps, Household Microwave, Washing Machines, Vacuum Cleaners, Card Readers, Swipe machine, fans, Electrical Items (Regulators, Meters, Switches, Starters, Chokes, converter, Wires, Cables etc), HUB's, Tube lights, CFLs, Bulbs, LCDs, Transformers and Condensers containing cooling oil, Industrial, Medical, Military & Space Electronics etc in ENVIRONMENTAL FRIENDLY WAY to Recover raw materials like Metals, Plastics & Glass.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Recycle

